<template>
  <div>
    <v-row v-if="loading" justify="center" class="ma-2">
      <v-progress-circular
        class="ma-6"
        style="text-center"
        :size="80"
        width="8"
        indeterminate
        color="primary"
      />
    </v-row>
    <v-form
      v-else
      v-model="mainFormValid"
      class="pt-6 pb-2 px-6"
    >
      <template v-for="(record, index) in internalRecordList">
        <text-record
          v-if="record.recordType.type === 'Texte'"
          :key="`record-${index}`"
          v-model="record.value"
          :name="record.recordType.name"
          :description="record.recordType.description"
          :mask="record.recordType.mask"
          :sampling="record.recordType.sampling"
          :ok.sync="record.ok"
          :completed.sync="record.completed"
          :error-message-list.sync="record.errorMessageList"
          @input="change"
        />
        <number-record
          v-if="record.recordType.type === 'Nombre'"
          :key="`record-${index}`"
          v-model="record.value"
          :name="record.recordType.name"
          :description="record.recordType.description"
          :mask="record.recordType.mask"
          :sampling="record.recordType.sampling"
          :interval="record.recordType.interval"
          :min="record.recordType.min"
          :max="record.recordType.max"
          :ok.sync="record.ok"
          :completed.sync="record.completed"
          :error-message-list.sync="record.errorMessageList"
          @input="change"
        />

        <permeability-record
          v-if="record.recordType.type === 'Perméabilité'"
          :key="`record-${index}`"
          v-model="record.value"
          :name="record.recordType.name"
          :description="record.recordType.description"
          :sampling="record.recordType.sampling"
          :interval="record.recordType.interval"
          :min="record.recordType.min"
          :max="record.recordType.max"
          :ok.sync="record.ok"
          :completed.sync="record.completed"
          :error-message-list.sync="record.errorMessageList"
          @input="change"
        />

        <ok-ko-record
          v-if="record.recordType.type === 'OK/KO'"
          :key="`record-${index}`"
          v-model="record.value"
          :name="record.recordType.name"
          :description="record.recordType.description"
          :sampling="record.recordType.sampling"
          :ok.sync="record.ok"
          :completed.sync="record.completed"
          :error-message-list.sync="record.errorMessageList"
          @input="change"
        />
        <date-record
          v-if="record.recordType.type === 'Date'"
          :key="`record-${index}`"
          v-model="record.value"
          :name="record.recordType.name"
          :description="record.recordType.description"
          :sampling="record.recordType.sampling"
          :ok.sync="record.ok"
          :completed.sync="record.completed"
          :error-message-list.sync="record.errorMessageList"
          @input="change"
        />
        <part-record
          v-if="record.recordType.part"
          :key="`record-${index}`"
          v-model="record.value"
          :items="partToUseList"
          :item-disabled="disableItemToUse"
          :name="record.recordType.name"
          :description="record.recordType.description"
          :sampling="record.recordType.sampling"
          :ok.sync="record.ok"
          :completed.sync="record.completed"
          :error-message-list.sync="record.errorMessageList"
          :timer="batch.partNumber.timer"
          :force-stock="record.recordType.type === 'Faisceau'"
          :stock.sync="record.stock"
          :switch-label="`
            Dernièr(e) ${batch.partType.name}
            fabriqué avec le ${record.recordType.type} :
            ${record.value ? record.value.serialNumber : ''}`"
          @input="change"
        />
      </template>
    </v-form>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import Repositories from 'src/repositories'
import TextRecord from './TextRecord'
import NumberRecord from './NumberRecord'
import OkKoRecord from './OkKoRecord'
import DateRecord from './DateRecord'
import PartRecord from './PartRecord'
import PermeabilityRecord from './PermeabilityRecord'

export default {
  name: 'AddRecordForm',
  components: {
    TextRecord, NumberRecord, OkKoRecord, DateRecord, PartRecord, PermeabilityRecord,
  },
  props: {
    recordList: {
      type: Array,
      default: () => [],
    },
    partNumber: {
      type: String,
      default: '',
    },
    batch: {
      type: Object,
      default: () => {},
    },
    role: {
      type: Object,
      default: () => {},
    },
  },
  data: () => ({
    internalRecordList: [],
    mainFormValid: false,
    partToUseList: [],
    loading: true,
  }),
  computed: {
    ...mapGetters('batch', {
      filtrationSurface: 'filtrationSurface',
    }),
    isValidRecordList () {
      // eslint-disable-next-line no-plusplus
      const valid = this.internalRecordList
        .reduce((prev, curr) => {
          if (!curr.ok) {
            // eslint-disable-next-line no-param-reassign
            prev = false
          }
          return prev
        }, true)
      return valid
    },
    isCompletedRecordList () {
      if (!this.internalRecordList.length) return false
      const completed = this.internalRecordList
        .reduce((prev, curr) => {
          if (!curr.completed && !curr.sampling) {
            // eslint-disable-next-line no-param-reassign
            prev = false
          }
          return prev
        }, true)
      return completed
    },
  },
  watch: {
    recordList: {
      immediate: true,
      handler (recordList) {
        if (!recordList.length) return
        this.initRecordData()
      },
    },
    isValidRecordList: {
      immediate: true,
      handler () {
        this.emitData()
      },
    },
  },
  methods: {
    change () {
      this.emitData()
    },
    async initRecordData () {
      this.loading = true
      const recordListToFill = []
      const partToUseIsRequired = this.recordList.some(record => record.part)
      if (partToUseIsRequired) {
        this.partToUseList = await Repositories.part
          .getListToUse(
            {
              partNumber: this.partNumber,
              all: false,
            },
          )
      }
      for (const record of this.recordList) {
        const tempRecord = {
          recordType: record,
          value: null,
          stock: false,
          ok: true,
          completed: false,
          errorMessageList: [],
        }
        if (record.part) {
          tempRecord.value = { serialNumber: '' }
          if (record.type === 'Faisceau') {
            tempRecord.stock = true
          }
        }
        if (!record.sampling && (record.type === 'OK/KO' || (record.type === 'Nombre' && record.interval) || (record.type === 'Perméabilité' && record.interval))) {
          tempRecord.ok = false
        }
        if (record.type === 'Perméabilité') {
          tempRecord.value = {
            surface: this.filtrationSurface,
            debit: null,
            pressure: null,
            temperature: null,
            permeability: null,
          }
        }
        if (record.sampling) tempRecord.completed = true

        recordListToFill.push(tempRecord)
      }
      this.internalRecordList = recordListToFill
      this.emitData()
      this.loading = false
    },
    emitData () {
      this.$emit('fill', this.internalRecordList)
      this.$emit('validate', this.isValidRecordList)
      this.$emit('complete', this.isCompletedRecordList)
    },
    checkIfAlreadySelected (item) {
      let alredySelected = false
      for (const record of this.internalRecordList) {
        // eslint-disable-next-line max-len
        if (record.value && record.value.serialNumber && record.value._id.toString() === item._id.toString()) {
          alredySelected = true
        }
      }
      return alredySelected
    },
    disableItemToUse (item) {
      return ((this.moment(item.finishAt).add(this.batch.partNumber.timer, 'h') - this.moment()) > 0 || this.checkIfAlreadySelected(item)) && this.role.level < 2
    },
  },
}
</script>
