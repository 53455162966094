<template>
  <v-text-field
    :class="!errorMessageList.length ? 'mb-5' : ''"
    :value="value"
    :label="label"
    type="date"
    :hint="description"
    persistent-hint
    hide-details="auto"
    prepend-icon="event"
    v-bind="$attrs"
    autocomplete="off"
    :success="success"
    :error-messages="errorMessageList"
    :error-count="errorMessageList.length"
    outlined
    @input="validationAfterInput($event)"
  />
</template>

<script>

export default {
  name: 'DateRecord',
  props: {
    value: {
      type: String || Date,
      default: '',
    },
    sampling: {
      type: Boolean,
      default: false,
    },
    description: {
      type: String,
      default: '',
    },
    name: {
      type: String,
      default: '',
    },
    ok: {
      type: Boolean,
      default: false,
    },
    completed: {
      type: Boolean,
      default: false,
    },
    errorMessageList: {
      type: Array,
      default: () => [],
    },
  },
  computed: {
    label () {
      if (!this.sampling) return `${this.name} *`
      return this.name
    },
    success () {
      if (this.value && this.value.length && this.ok && this.completed) return true
      return false
    },
  },
  watch: {
    value: {
      immediate: true,
      async handler (value) {
        if (value.includes('Z')) {
          this.validationAfterInput(this.moment(value).format('yyyy-MM-DD'))
        }
      },
    },
  },
  methods: {
    validationAfterInput (value) {
      let tempErrorMessage = []
      tempErrorMessage = this.checkIfValueIsCompleted(value, tempErrorMessage)
      this.$emit('update:error-message-list', tempErrorMessage)
      this.$emit('input', value)
    },
    checkIfValueIsCompleted (value, messageErrorList) {
      let tempcompleted = true
      if (!this.sampling && (value === null || value === '')) {
        tempcompleted = false
        messageErrorList.push('Veuilez saisir une valeur valide')
      }
      this.$emit('update:completed', tempcompleted)
      return messageErrorList
    },
  },
}
</script>
