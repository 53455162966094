<template>
  <div>
    <close-open-operation-container :batch-operation="batchOperation" />
    <operation-information
      :information-to-display="batchOperation.informationToDisplay"
      :instructions="batchOperation.operation.instructions"
      :document="batchOperation.operation.document"
    />
  </div>
</template>
<script>
import OperationInformation from '../components/OperationInformation'
import CloseOpenOperationContainer from './CloseOpenOperationContainer'

export default {
  name: 'OperationInformationContainer',
  components: {
    OperationInformation,
    CloseOpenOperationContainer,
  },
  props: {
    batchOperation: {
      type: Object,
      required: true,
    },
  },
  data: () => ({}),
  computed: {},
}
</script>
