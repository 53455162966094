<template>
  <div>
    <v-tabs
      v-model="tab"
      hide-slider
      grow
      background-color="secondary lighten-3"
      @change="setCurrentOperationProductionTab($event)"
    >
      <v-tab
        v-if="batchOperation.BatchRecordRequired"
        v-text="'Contrôles opération'"
      />
      <v-tab
        v-if="batchOperation.operation.partsRecords.length"
        v-text="todoLabel"
      />
      <v-tab
        v-if="batchOperation.operation.partsRecords.length"
        v-text="`Pièces produite (${batchOperation.doneList.length})`"
      />
      <v-tab
        v-if="batchOperation.operation.partsRecords.length"
        v-text="`Pièces en quarantaine (${suspendedPartCount})`"
      />
    </v-tabs>
    <v-tabs-items v-model="tab">
      <v-tab-item v-if="batchOperation.BatchRecordRequired" eager>
        <operation-record-container :batch-operation="batchOperation" />
      </v-tab-item>
      <v-tab-item v-if="batchOperation.operation.partsRecords.length" eager>
        <operation-part-list-todo-container :batch-operation="batchOperation" />
      </v-tab-item>
      <v-tab-item v-if="batchOperation.operation.partsRecords.length" eager>
        <operation-part-list-done-container :batch-operation="batchOperation" />
      </v-tab-item>
      <v-tab-item v-if="batchOperation.operation.partsRecords.length" eager>
        <operation-part-list-suspended-container :batch-operation="batchOperation" />
      </v-tab-item>
    </v-tabs-items>
    <router-view />
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import OperationRecordContainer from './OperationRecordContainer'
import OperationPartListTodoContainer from './OperationPartListTodoContainer'
import OperationPartListDoneContainer from './OperationPartListDoneContainer'
import OperationPartListSuspendedContainer from './OperationPartListSuspendedContainer'

export default {
  name: 'OperationControlContainer',
  components: {
    OperationRecordContainer,
    OperationPartListTodoContainer,
    OperationPartListDoneContainer,
    OperationPartListSuspendedContainer,
  },
  props: {
    batchOperation: {
      type: Object,
      required: true,
    },
  },
  data: () => ({}),
  computed: {
    ...mapGetters('batch', {
      batch: 'batch',
    }),
    ...mapGetters('ui', {
      currentOperationProductionTab: 'currentOperationProductionTab',
    }),
    tab: {
      get () {
        return this.currentOperationProductionTab
      },
      set (newValue) {
        this.setCurrentOperationProductionTab(newValue)
      },
    },
    todoLabel () {
      if (this.batchOperation.operation.operationType.isDelay) {
        return `Pièces à produire (${this.batchOperation.availableTodoList.length} / ${this.batchOperation.todoList.length})`
      }
      return `Pièces à produire (${this.batchOperation.todoList.length})`
    },
    suspendedPartCount () {
      if (this.batchOperation.operation.operationType.control) {
        return this.batchOperation.stats.controlSuspendedList.length
      }
      return this.batchOperation.stats.suspendedList.length
    },
  },
  methods: {
    ...mapActions('ui', {
      setCurrentOperationProductionTab: 'setCurrentOperationProductionTab',
    }),
  },
}
</script>
<style scoped>
.v-tab {
    color: white !important;
}
.v-tab--active {
    color: inherit;
    background-color: var(--v-primary-base) !important;
    color: white !important;
}
</style>
