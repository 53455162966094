<template>
  <v-list three-line class="pa-0">
    <template v-for="(item, index) in declarationList">
      <v-list-item
        :key="item._id"
        class="px-0"
      >
        <v-list-item-content class="px-4" :class="getItemClass(item.type)">
          <v-list-item-title>
            {{ item.type }} à l'opération : {{ item.operationType.name }}
          </v-list-item-title>
          <v-list-item-title class="text--primary">
            Cause : {{ item.rejectionCauses.name }}
          </v-list-item-title>
          <v-list-item-title class="text--primary">
            Commentaire : {{ item.justification }}
          </v-list-item-title>
          <v-list-item-title class="text--primary">
            Utilisateur : {{ item.user.firstname }} {{ item.user.lastname }}
          </v-list-item-title>
          <v-list-item-title>
            {{ moment(item.declareAt).format('LLLL') }}
          </v-list-item-title>
        </v-list-item-content>
      </v-list-item>
      <v-divider
        v-if="index + 1 < declarationList.length"
        :key="index"
      />
    </template>
  </v-list>
</template>

<script>

export default {
  name: 'DeclarationDetailsList',
  props: {
    declarationList: {
      type: Array,
      default: () => [],
    },
  },
  computed: {},
  watch: {},
  methods: {
    getItemClass (type) {
      switch (type) {
        case 'Rebut':
          return 'rejected'
        case 'Retouche':
          return 'retouched'
        case 'Dérogation':
          return 'exception'
        case 'Quarantaine':
          return 'suspended'
        case 'Décision en suspens':
          return 'controlSuspended'
        default:
          return ''
      }
    },
  },
}
</script>
