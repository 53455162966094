<template>
  <v-container fluid>
    <v-card>
      <v-card-text v-if="!partList.length" class="text-h4 text-center">
        Aucune pièces en quarantaine
      </v-card-text>
      <part-list
        v-else
        list-type="Suspended"
        :multi-selection="isMultiSelectionAllowed"
        :value="partList"
        :is-delay="false"
        :selected.sync="selectedPartList"
        :item-per-page-allowed="itemPerPageAllowed"
        action-button-icon="search"
        @openOnePart="viewOnePart"
      />
    </v-card>
    <ag-float-button-container>
      <v-fab-transition>
        <v-btn
          v-if="selectedPartList.length"
          append
          color="accent"
          fab
          @click="openEditMultiplePartrecordDialog"
        >
          <v-icon>edit</v-icon>
          <v-chip
            x-small
            class="px-1 float-chip font-weight-black"
            color="white"
            text-color="black"
            v-text="selectedPartList.length"
          />
        </v-btn>
      </v-fab-transition>
    </ag-float-button-container>
    <view-edit-one-part-record-container
      v-if="displayEditOnePartDialog && selectedPart"
      :part="selectedPart"
      :record-list="batchOperation.operation.partsRecords"
      :batch-operation="batchOperation"
      :declaration-mode="true"
      @close="closeViewOnePartRecordDialog"
      @takeDecision="takeDecision"
    />
    <view-edit-multiple-part-record-container
      v-if="editMultiplePartRecordDialog && selectedPartList.length"
      :part-list="selectedPartList"
      :record-list="batchOperation.operation.partsRecords"
      :batch-operation="batchOperation"
      :declaration-mode="true"
      @close="closeEditMultiplePartrecordDialog"
      @takeDecision="takeDecision"
    />
    <rejection-selection
      v-if="rejectionDialog"
      :batch-operation="batchOperation"
      :declaration-mode="true"
      @close="closeRejectionSelection"
      @validation="validateRejection"
    />
  </v-container>
</template>
<script>
import { mapActions, mapGetters } from 'vuex'
import Repositories from 'src/repositories'
import AgFloatButtonContainer from 'src/components/common/AgFloatButtonContainer'
import ViewEditMultiplePartRecordContainer from 'src/modules/openBatch/operation/containers/ViewEditMultiplePartRecordContainer'
import PartList from '../components/PartList'
import ViewEditOnePartRecordContainer from './ViewEditOnePartRecordContainer'
import RejectionSelection from '../components/RejectionSelection'

export default {
  name: 'OperationPartListDoneContainer',
  components: {
    PartList,
    AgFloatButtonContainer,
    ViewEditOnePartRecordContainer,
    ViewEditMultiplePartRecordContainer,
    RejectionSelection,
  },
  props: {
    batchOperation: {
      type: Object,
      required: true,
    },
  },
  data: () => ({
    selectedPartList: [],
    selectedPart: null,
    displayEditOnePartDialog: false,
    editMultiplePartRecordDialog: false,
    rejectionDialog: false,
  }),
  computed: {
    ...mapGetters('batch', {
      batch: 'batch',
    }),
    ...mapGetters('me', {
      role: 'role',
      userId: 'userId',
    }),
    itemPerPageAllowed () {
      if (this.batchOperation.operation.operationType.itemPerPageAllowedList.length) {
        const temp = this.batchOperation.operation.operationType.itemPerPageAllowedList.map(
          value => ({ text: value, value }),
        )
        if (
          this.batchOperation.operation.operationType.allItemPerPageAllowed
          || this.role.level > 1
        ) {
          temp.push({ text: 'Tous', value: this.partList.length })
        }
        return temp
      }
      const temp = [
        { text: 3, value: 3 },
        { text: 6, value: 6 },
        { text: 10, value: 10 },
        { text: 20, value: 20 },
      ]
      if (
        this.batchOperation.operation.operationType.allItemPerPageAllowed
          || this.role.level > 1
      ) {
        temp.push({ text: 'Tous', value: this.partList.length })
      }
      return temp
    },
    isMultiSelectionAllowed () {
      if (this.role.level > 1) return true
      return false
    },
    partList () {
      if (this.batchOperation.operation.operationType.control) {
        return this.batchOperation.stats.controlSuspendedList
      }
      return this.batchOperation.stats.suspendedList
    },
  },
  methods: {
    ...mapActions('ui', {
      showSuccessNotification: 'showSuccessNotification',
      showErrorNotification: 'showErrorNotification',
    }),
    ...mapActions('batch', {
      getBatch: 'getBatch',
    }),
    viewOnePart (part) {
      this.selectedPart = part
      this.displayEditOnePartDialog = true
    },
    closeViewOnePartRecordDialog () {
      this.displayEditOnePartDialog = false
      this.selectedPartList = []
      this.selectedPart = null
    },
    openEditMultiplePartrecordDialog () {
      this.editMultiplePartRecordDialog = true
    },
    closeEditMultiplePartrecordDialog () {
      this.editMultiplePartRecordDialog = false
      this.selectedPartList = []
      this.selectedPart = null
    },
    closeRejectionSelection () {
      this.rejectionDialog = false
      this.selectedPartList = []
      this.selectedPart = null
    },
    takeDecision () {
      this.displayEditOnePartDialog = false
      this.editMultiplePartRecordDialog = false
      this.rejectionDialog = true
    },
    async validateRejection (decision) {
      const partIdList = this.selectedPart
        ? [this.selectedPart._id]
        : this.selectedPartList.map(part => part._id)
      try {
        await Repositories.record.declarationForSuspendedPart({
          batchId: this.batch._id,
          partIdList,
          decision,
          batchOperationId: this.batchOperation._id,
          isControlOperation: this.batchOperation.operation.operationType.control,
          user: this.userId,
        })
        this.showSuccessNotification('La/les décisions ont bien été enregistrées')
        this.rejectionDialog = false
        await this.getBatch(this.batch._id)
      }
      catch (error) {
        this.showErrorNotification(Repositories.parseErrorMessage(error))
      }
    },
  },
}
</script>
<style lang="scss" scoped>
.float-chip {
  cursor: pointer;
  position: absolute;
  right: 10px;
  top: 12px;
}
</style>
