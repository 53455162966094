<template>
  <ag-modal-container
    :title="title"
    width="600px"
    no-content-padding
    @close="close"
  >
    <add-record-form
      :record-list="recordList"
      :part-number="partNumber"
      :batch="batch"
      :role="role"
      @validate="isValid = $event"
      @complete="isComplete = $event"
      @fill="recordListFilled = $event"
    />
    <template v-slot:footer>
      <v-spacer />
      <v-btn
        :loading="loading"
        :disabled="loading || !isComplete"
        color="accent"
        class="ml-5"
        @click="validate(false)"
        v-text="'Valider'"
      />
      <v-btn
        v-if="canChainPart"
        :loading="loading"
        :disabled="loading || !isComplete"
        color="accent"
        class="ml-5"
        @click="validate(true)"
        v-text="'Valider et saisir la pièce suivante'"
      />
      <v-spacer />
    </template>
    <ag-confirm-dialog
      v-model="dropStockConfirmationDialog"
      title="Confirmation"
      :message="dropStockTitle"
      cancel-label="Annuler"
      confirm-label="Valider"
      validation-btn-color="accent"
      :max-width="500"
      @confirm="dropStockValidation"
    />
    <rejection-selection
      v-if="RejectionDialog"
      :batch-operation="batchOperation"
      @close="RejectionDialog = false"
      @validation="validateRejection"
    />
  </ag-modal-container>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import Repositories from 'src/repositories'

import AgModalContainer from 'src/components/common/AgModalContainer'
import AgConfirmDialog from 'src/components/common/AgConfirmDialog'
import AddRecordForm from '../components/AddRecordForm'
import RejectionSelection from '../components/RejectionSelection'

export default {
  name: 'AddPartRecordContainer',
  components: {
    AgModalContainer,
    AddRecordForm,
    AgConfirmDialog,
    RejectionSelection,
  },
  props: {
    batchOperation: {
      type: Object,
      required: true,
    },
    partList: {
      type: Array,
      default: () => [],
    },
    recordList: {
      type: Array,
      default: () => [],
    },
  },
  data: () => ({
    wantToChainPartEntry: false,
    isValid: false,
    isComplete: false,
    loading: false,
    recordListFilled: [],
    dropStockValidated: false,
    dropStockConfirmationDialog: false,
    RejectionDialog: false,
    decision: {
      cause: '',
      value: null,
    },
    rejectionCauseList: [],
  }),
  computed: {
    ...mapGetters('batch', {
      batch: 'batch',
    }),
    ...mapGetters('me', {
      role: 'role',
      userId: 'userId',
    }),
    canChainPart () {
      return this.batchOperation.operation.operationType.canChainPartEntry
        && this.partList.length === 1
    },
    multiplePart () {
      return this.partList.length > 1
    },
    title () {
      if (this.multiplePart) {
        return `Saisie de ${this.partList.length} pièces :${this.partList[0].serialNumber} à ${this.partList[this.partList.length - 1].serialNumber}`
      }
      return `Saisie de la pièce ${this.partList[0].serialNumber}`
    },
    partNumber () {
      return this.batch.partNumber._id
    },
    dropStockToValidate () {
      return this.recordListFilled.some(item => item.stock)
    },
    dropStockTitle () {
      let title = ''
      for (const record of this.recordListFilled) {
        if (record.stock && record.value && record.value._id) {
          title += `Êtes vous sûr d'avoir finis d'utiliser l'élément : ${record.recordType.type} - ${record.value.serialNumber} ?  Il ne sera plus utilisable par la suite !
        `
        }
      }
      return title
    },
  },
  methods: {
    ...mapActions('ui', {
      showSuccessNotification: 'showSuccessNotification',
      showErrorNotification: 'showErrorNotification',
    }),
    close () {
      this.$emit('close')
    },
    validate (wantToChainPartEntry) {
      this.wantToChainPartEntry = wantToChainPartEntry
      if (this.dropStockToValidate && !this.dropStockValidated) {
        this.dropStockConfirmationDialog = true
        return
      }
      if (!this.isValid) {
        this.RejectionDialog = true
      }
      else {
        this.saveRecord(null)
      }
    },
    dropStockValidation () {
      this.dropStockValidated = true
      this.validate()
    },
    validateRejection (decision) {
      this.rejectionDialog = false
      this.saveRecord(decision)
    },
    async saveRecord (decision) {
      const params = []
      let partIdList = []
      for (const record of this.recordListFilled) {
        params.push({
          recordType: record.recordType._id,
          partNumber: this.partNumber,
          operation: this.batchOperation._id,
          operationType: this.batchOperation.operation.operationType._id,
          batch: this.batch._id,
          user: this.userId,
          value: record.value,
          type: record.recordType.type,
          part: record.recordType.part,
          ok: record.ok,
          sampling: record.recordType.sampling,
          stock: record.stock,
          destruction: record.recordType.destroy,
        })
      }
      partIdList = this.partList.map(item => item._id)
      this.loading = true
      try {
        await Repositories.record.post({
          batchId: this.batch._id,
          partIdList,
          recordList: params,
          decision,
          operationTypeId: this.batchOperation.operation.operationType._id,
          operationBatchId: this.batchOperation._id,
          batchTypeCreation: false,
        })
        this.showSuccessNotification('Les relevés ont bien été créés')
        await this.$store.dispatch('batch/donePart', {
          batchId: this.batch._id,
          partIdList,
          recordList: params,
          decision,
          operationType: this.batchOperation.operation.operationType,
          operationBatchId: this.batchOperation._id,
          batchTypeCreation: false,
        })
        if (this.wantToChainPartEntry) {
          this.RejectionDialog = false
          this.$emit('nextPart', this.partList[0])
        }
        else {
          this.close()
        }
      }
      catch (error) {
        this.showErrorNotification(Repositories.parseErrorMessage(error))
      }
      finally {
        this.loading = false
      }
    },
  },

}
</script>
