<template>
  <v-container>
    <v-row v-if="!informationToDisplay">
      <v-col cols="12">
        <v-card flat>
          <v-card-text class="text-h4 text-center">
            Il n'y a pas d'informations pour cette opération
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>

    <v-row v-else no-gutters>
      <v-col v-if="instructions" cols="12" class="pa-8">
        <v-textarea
          :value="instructions"
          hide-details
          filled
          rows="6"
          readonly
          label="Instructions"
        />
      </v-col>
      <v-col v-if="document" cols="12">
        <iframe
          :src="'https://polymem-admin.agilea-software.com' + document.file"
          frameborder="0"
          width="100%"
          height="800px"
        />
      </v-col>
    </v-row>
  </v-container>
</template>
<script>

export default {
  name: 'OperationInformation',
  props: {
    informationToDisplay: {
      type: Boolean,
      default: false,
    },
    instructions: {
      type: String,
      default: '',
    },
    document: {
      type: [Object, null],
      default: null,
    },
  },
}
</script>
