<template>
  <v-text-field
    v-mask="mask"
    :class="!errorMessageList.length ? 'mb-5' : 'mb-5'"
    :placeholder="mask"
    :value="value"
    :label="label"
    :type="mask ? 'text' : 'number'"
    :hint="description"
    persistent-hint
    hide-details="auto"
    prepend-icon="keyboard"
    :append-outer-icon=" (value && !ok) ? 'error' : ''"
    v-bind="$attrs"
    autocomplete="off"
    :success="success"
    :error-messages="errorMessageList"
    :error-count="errorMessageList.length"
    outlined
    @input="validationAfterInput($event)"
  />
</template>

<script>
export default {
  name: 'NumberRecord',
  props: {
    value: {
      type: [String, Number],
      default: '',
    },
    sampling: {
      type: Boolean,
      default: false,
    },
    description: {
      type: String,
      default: '',
    },
    name: {
      type: String,
      default: '',
    },
    mask: {
      type: String,
      default: '',
    },
    interval: {
      type: Boolean,
      default: false,
    },
    min: {
      type: String,
      default: '',
    },
    max: {
      type: String,
      default: '',
    },
    ok: {
      type: Boolean,
      default: false,
    },
    completed: {
      type: Boolean,
      default: false,
    },
    errorMessageList: {
      type: Array,
      default: () => [],
    },
  },
  computed: {
    label () {
      if (!this.sampling) return `${this.name} *`
      return this.name
    },
    success () {
      if (this.value && this.value.length && this.ok && this.completed) return true
      return false
    },
  },
  methods: {
    validationAfterInput (value) {
      let tempErrorMessage = []
      tempErrorMessage = this.checkIfValueIsCompleted(value, tempErrorMessage)
      tempErrorMessage = this.checkIfValueIsOk(value, tempErrorMessage)
      this.$emit('update:error-message-list', tempErrorMessage)
      this.$emit('input', value)
    },
    checkIfValueIsOk (value, messageErrorList) {
      if (this.sampling && !value.length) {
        this.$emit('update:ok', true)
        return messageErrorList
      }
      if (!value) {
        this.$emit('update:ok', false)
        return messageErrorList
      }
      let tempOk = true
      if (this.interval && this.min) {
        if (value < Number.parseFloat(this.min)) {
          tempOk = false
          messageErrorList.push(`La valeur doit être supérieur à ${this.min}`)
        }
      }
      if (this.interval && this.max) {
        if (value > Number.parseFloat(this.max)) {
          tempOk = false
          messageErrorList.push(`La valeur doit être inférieur à ${this.max}`)
        }
      }
      this.$emit('update:ok', tempOk)
      return messageErrorList
    },
    checkIfValueIsCompleted (value, messageErrorList) {
      let tempcompleted = true
      if (!this.sampling || (value.length && this.mask)) {
        if (!value && value !== 0) {
          tempcompleted = false
        }
        if (value.length < this.mask.length) {
          tempcompleted = false
          messageErrorList.push('Veuilez saisir une valeur valide')
        }
      }
      this.$emit('update:completed', tempcompleted)
      return messageErrorList
    },
  },
}
</script>
