<template>
  <div v-if="batchOperation">
    <close-open-operation-container
      v-if="batchOperation.status === 2"
      :batch-operation="batchOperation"
    />
    <v-tabs
      v-else-if="batchOperation.status < 2"
      v-model="tab"
      hide-slider
      grow
      background-color="secondary lighten-3"
      @change="setCurrentOperationTab($event)"
    >
      <v-tab
        v-text="'INFORMATIONS'"
      />
      <v-tab
        v-if="batchOperation.status !== 2"
        v-text="'Contrôles : Production'"
      />
    </v-tabs>
    <v-tabs-items v-if="batchOperation && batchOperation.status < 2" v-model="tab">
      <v-tab-item eager>
        <operation-information-container :batch-operation="batchOperation" />
      </v-tab-item>
      <v-tab-item v-if="batchOperation.status !== 2" eager>
        <operation-control-container :batch-operation="batchOperation" />
      </v-tab-item>
    </v-tabs-items>
    <router-view />
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import OperationInformationContainer from './OperationInformationContainer'
import OperationControlContainer from './OperationControlContainer'
import CloseOpenOperationContainer from './CloseOpenOperationContainer'

export default {
  name: 'OperationContainer',
  components: {
    OperationInformationContainer,
    OperationControlContainer,
    CloseOpenOperationContainer,
  },
  data: () => ({
    timer: null,
  }),
  computed: {
    ...mapGetters('batch', {
      batch: 'batch',
      batchOperationGetter: 'batchOperation',
    }),
    ...mapGetters('ui', {
      currentOperationTab: 'currentOperationTab',
    }),
    tab: {
      get () {
        return this.currentOperationTab
      },
      set (newValue) {
        this.setCurrentOperationTab(newValue)
      },
    },
    operationId () {
      return this.$route.params.operationId
    },
    batchOperation () {
      return this.batchOperationGetter(this.operationId)
    },
  },
  watch: {},
  methods: {
    ...mapActions('ui', {
      setCurrentOperationTab: 'setCurrentOperationTab',
    }),
  },
}
</script>
<style scoped>
.v-tab {
    color: white !important;
}
.v-tab--active {
    color: inherit;
    background-color: var(--v-primary-base) !important;
    color: white !important;
}
</style>
