<template>
  <v-card v-if="batchOperation.canBeClose || batchOperation.status === 2" flat>
    <v-card-text class="text-center">
      <v-btn
        v-if="batchOperation.canBeClose && batchOperation.status === 1"
        color="accent"
        block
        @click="closeOperationConfirmation = true"
      >
        Clôturer l'opération
      </v-btn>
      <h1 v-if="batchOperation.status === 2" class="pa-6 text-uppercase">
        L'opération est close
      </h1>
      <v-btn
        v-if="batchOperation.status === 2 && role.level > 1"
        color="accent"
        block
        @click="openOperationConfirmation = true"
      >
        Réouvrir l'opération
      </v-btn>
    </v-card-text>
    <ag-confirm-dialog
      v-model="closeOperationConfirmation"
      title="Confirmation"
      :message="closeConfirmationText"
      cancel-label="Annuler"
      confirm-label="Valider"
      validation-btn-color="accent"
      @confirm="closeOperation"
    />
    <ag-confirm-dialog
      v-model="openOperationConfirmation"
      title="Confirmation"
      :message="openConfirmationText"
      cancel-label="Annuler"
      confirm-label="Valider"
      validation-btn-color="accent"
      @confirm="openOperation"
    />
  </v-card>
</template>
<script>
import { mapActions, mapGetters } from 'vuex'
import AgConfirmDialog from 'src/components/common/AgConfirmDialog'
import Repositories from 'src/repositories'

export default {
  name: 'CloseOpenOperationContainer',
  components: {
    AgConfirmDialog,
  },
  props: {
    batchOperation: {
      type: Object,
      required: true,
    },
  },
  data: () => ({
    closeOperationConfirmation: false,
    openOperationConfirmation: false,
  }),
  computed: {
    ...mapGetters('batch', {
      batch: 'batch',
    }),
    ...mapGetters('me', {
      role: 'role',
    }),
    closeConfirmationText () {
      return `Êtes vous sûr de vouloir clôturer l'opération : ${this.batchOperation.operation.operationType.name}`
    },
    openConfirmationText () {
      return `Êtes vous sûr de vouloir réouvrir l'opération : ${this.batchOperation.operation.operationType.name}`
    },
  },
  methods: {
    ...mapActions('ui', {
      showSuccessNotification: 'showSuccessNotification',
      showErrorNotification: 'showErrorNotification',
    }),
    ...mapActions('batch', {
      getBatch: 'getBatch',
    }),
    async closeOperation () {
      try {
        await Repositories.batch.closeOperation({
          batchId: this.batch._id,
          operationBatchId: this.batchOperation._id,
        })
        this.showSuccessNotification('L\'opération a bien été clôturée')
        await this.getBatch(this.batch._id)
      }
      catch (error) {
        this.showErrorNotification(Repositories.parseErrorMessage(error))
      }
    },
    async openOperation () {
      try {
        await Repositories.batch.openOperation({
          batchId: this.batch._id,
          operationBatchId: this.batchOperation._id,
        })
        this.showSuccessNotification('L\'opération a bien été réouverte')
        await this.getBatch(this.batch._id)
      }
      catch (error) {
        this.showErrorNotification(Repositories.parseErrorMessage(error))
      }
    },
  },
}
</script>
