<template>
  <v-text-field
    v-mask="mask"
    :class="!errorMessageList.length ? 'mb-5' : 'mb-5'"
    :placeholder="mask"
    :value="value"
    :label="label"
    type="text"
    :hint="description"
    persistent-hint
    hide-details="auto"
    prepend-icon="keyboard"
    v-bind="$attrs"
    autocomplete="off"
    :success="success"
    :error-messages="errorMessageList"
    :error-count="errorMessageList.length"
    outlined
    @input="validationAfterInput($event)"
  />
</template>

<script>

export default {
  name: 'TextRecord',
  props: {
    value: {
      type: String,
      default: '',
    },
    sampling: {
      type: Boolean,
      default: false,
    },
    description: {
      type: String,
      default: '',
    },
    name: {
      type: String,
      default: '',
    },
    mask: {
      type: String,
      default: '',
    },
    ok: {
      type: Boolean,
      default: false,
    },
    completed: {
      type: Boolean,
      default: false,
    },
    errorMessageList: {
      type: Array,
      default: () => [],
    },
  },
  computed: {
    label () {
      if (!this.sampling) return `${this.name} *`
      return this.name
    },
    success () {
      if (this.value && this.value.length && this.ok && this.completed) return true
      return false
    },
  },
  methods: {
    validationAfterInput (value) {
      let tempErrorMessage = []
      tempErrorMessage = this.checkIfValueIsCompleted(value, tempErrorMessage)
      this.$emit('update:error-message-list', tempErrorMessage)
      this.$emit('input', value)
    },
    checkIfValueIsCompleted (value, messageErrorList) {
      let tempcompleted = true
      if (!this.sampling || (value.length && this.mask)) {
        if (!value && value !== 0) {
          tempcompleted = false
        }
        if (value.length < this.mask.length) {
          tempcompleted = false
          messageErrorList.push('Veuilez saisir une valeur valide')
        }
      }
      this.$emit('update:completed', tempcompleted)
      return messageErrorList
    },
  },
}
</script>
