<template>
  <div>
    <v-row v-if="loading" justify="center" class="ma-2">
      <v-progress-circular
        class="ma-6"
        style="text-center"
        :size="80"
        width="8"
        indeterminate
        color="primary"
      />
    </v-row>
    <v-form
      v-else
      v-model="mainFormValid"
      :disabled="!editionMode"
      class="pt-6 pb-2 px-6"
    >
      <template v-for="(record, index) in internalRecordList">
        <text-record
          v-if="record.recordType.type === 'Texte'"
          :key="`record-${index}`"
          v-model="record.value"
          :name="record.recordType.name"
          :description="record.recordType.description"
          :mask="record.recordType.mask"
          :sampling="record.recordType.sampling"
          :ok.sync="record.ok"
          :completed.sync="record.completed"
          :error-message-list.sync="record.errorMessageList"
          @input="change"
        />
        <number-record
          v-if="record.recordType.type === 'Nombre'"
          :key="`record-${index}`"
          v-model="record.value"
          :name="record.recordType.name"
          :description="record.recordType.description"
          :mask="record.recordType.mask"
          :sampling="record.recordType.sampling"
          :interval="record.recordType.interval"
          :min="record.recordType.min"
          :max="record.recordType.max"
          :ok.sync="record.ok"
          :completed.sync="record.completed"
          :error-message-list.sync="record.errorMessageList"
          @input="change"
        />
        <permeability-record
          v-if="record.recordType.type === 'Perméabilité'"
          :key="`record-${index}`"
          v-model="record.value"
          :name="record.recordType.name"
          :description="record.recordType.description"
          :sampling="record.recordType.sampling"
          :interval="record.recordType.interval"
          :min="record.recordType.min"
          :max="record.recordType.max"
          :ok.sync="record.ok"
          :completed.sync="record.completed"
          :error-message-list.sync="record.errorMessageList"
          @input="change"
        />
        <ok-ko-record
          v-if="record.recordType.type === 'OK/KO'"
          :key="`record-${index}`"
          v-model="record.value"
          :name="record.recordType.name"
          :description="record.recordType.description"
          :sampling="record.recordType.sampling"
          :ok.sync="record.ok"
          :completed.sync="record.completed"
          :error-message-list.sync="record.errorMessageList"
          @input="change"
        />
        <date-record
          v-if="record.recordType.type === 'Date'"
          :key="`record-${index}`"
          v-model="record.value"
          :name="record.recordType.name"
          :description="record.recordType.description"
          :sampling="record.recordType.sampling"
          :ok.sync="record.ok"
          :completed.sync="record.completed"
          :error-message-list.sync="record.errorMessageList"
          @input="change"
        />
        <part-record
          v-if="record.recordType.part"
          :key="`record-${index}`"
          v-model="record.value"
          :items="partToUseList"
          :name="record.recordType.name"
          :description="record.recordType.description"
          :sampling="record.recordType.sampling"
          :ok.sync="record.ok"
          :completed.sync="record.completed"
          :error-message-list.sync="record.errorMessageList"
          :timer="batch.partNumber.timer"
          :force-stock="record.recordType.type === 'Faisceau'"
          :stock.sync="record.stock"
          :switch-label="`
            Dernièr(e) ${batch.partType.name}
            fabriqué avec le ${record.recordType.type} :
            ${record.value ? record.value.serialNumber : ''}`"
          @input="change"
        />
      </template>
      <p>
        {{ isDoneBy }}
      </p>
    </v-form>
  </div>
</template>

<script>
import Repositories from 'src/repositories'
import TextRecord from './TextRecord'
import NumberRecord from './NumberRecord'
import OkKoRecord from './OkKoRecord'
import DateRecord from './DateRecord'
import PartRecord from './PartRecord'
import PermeabilityRecord from './PermeabilityRecord'

export default {
  name: 'DisplayEditAddRecordForm',
  components: {
    TextRecord, NumberRecord, OkKoRecord, DateRecord, PartRecord, PermeabilityRecord,
  },
  props: {
    recordList: {
      type: Array,
      default: () => [],
    },
    partNumber: {
      type: String,
      default: '',
    },
    batch: {
      type: Object,
      default: () => {},
    },
    role: {
      type: Object,
      default: () => {},
    },
    editionMode: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
    internalRecordList: [],
    mainFormValid: false,
    partToUseList: [],
    loading: true,
  }),
  computed: {
    isValidRecordList () {
      // eslint-disable-next-line no-plusplus
      const valid = this.internalRecordList
        .reduce((prev, curr) => {
          if (!curr.ok) {
            // eslint-disable-next-line no-param-reassign
            prev = false
          }
          return prev
        }, true)
      return valid
    },
    isCompletedRecordList () {
      const completed = this.internalRecordList
        .reduce((prev, curr) => {
          if (!curr.completed && !curr.sampling) {
            // eslint-disable-next-line no-param-reassign
            prev = false
          }
          return prev
        }, true)
      return completed
    },
    isDoneBy () {
      if (this.recordList.length) {
        return `Réalisé par : ${this.recordList[0].user.firstname}
      ${this.recordList[0].user.lastname},
      le ${this.moment(this.recordList[0].createdAt).format('LLLL')}`
      }
      return ''
    },
  },
  watch: {
    recordList: {
      immediate: true,
      handler (recordList) {
        if (!recordList.length) return
        this.initRecordData()
      },
    },
    isValidRecordList: {
      immediate: true,
      handler () {
        this.emitData()
      },
    },
  },
  methods: {
    change () {
      this.emitData()
    },
    async initRecordData () {
      this.loading = true
      const recordListToFill = []
      const partToUseIsRequired = this.recordList.some(record => record.recordType.part)
      if (partToUseIsRequired) {
        this.partToUseList = await Repositories.part
          .getListToUse(
            {
              partNumber: this.partNumber,
              all: true,
            },
          )
      }
      for (const record of this.recordList) {
        const tempRecord = {
          recordType: record.recordType,
          value: record.value,
          stock: false,
          ok: record.ok,
          completed: record.value !== null,
          errorMessageList: [],
        }
        if (record.recordType.part) {
          if (record.recordType.type === 'Faisceau') {
            tempRecord.stock = true
          }
        }
        if (record.recordType.sampling) tempRecord.completed = true

        recordListToFill.push(tempRecord)
      }
      this.internalRecordList = recordListToFill
      this.emitData()
      this.loading = false
    },
    emitData () {
      this.$emit('fill', this.internalRecordList)
      this.$emit('validate', this.isValidRecordList)
      this.$emit('complete', this.isCompletedRecordList)
    },
  },
}
</script>
