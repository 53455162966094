<template>
  <v-container fluid>
    <v-card>
      <v-card-text v-if="batchOperation.batchRecordTodo" class="text-h4 text-center">
        Vous devez réaliser les contrôles de l'opération avant de pouvoir produire les pièces
      </v-card-text>
      <v-card-text v-else-if="!batchOperation.todoList.length" class="text-h4 text-center">
        Il n'y a pas de pièce à produire
      </v-card-text>
      <part-list
        v-else
        id="todo-list"
        list-type="Todo"
        :multi-selection="isMultiSelectionAllowed"
        :value="batchOperation.todoList"
        :is-delay="batchOperation.operation.operationType.isDelay"
        :delay-hours="batchOperation.operation.operationType.delay"
        :selected.sync="selectedPartList"
        :item-per-page-allowed="itemPerPageAllowed"
        :user-level="role.level"
        @openOnePart="openOnePart"
      />
    </v-card>
    <ag-float-button-container left>
      <v-fab-transition>
        <v-btn
          v-if="selectedPartList.length && role.level > 1"
          append
          color="error"
          fab
          @click="openDeletePartConfirmationDialog"
        >
          <v-icon>delete</v-icon>
          <v-chip
            x-small
            class="px-1 float-chip font-weight-black"
            color="white"
            text-color="error"
            v-text="selectedPartList.length"
          />
        </v-btn>
      </v-fab-transition>
    </ag-float-button-container>
    <ag-float-button-container>
      <v-fab-transition>
        <v-btn
          v-if="selectedPartList.length"
          append
          color="accent"
          fab
          class="ml-1"
          @click="openAddrecordDialog"
        >
          <v-icon>edit</v-icon>
          <v-chip
            x-small
            class="px-1 float-chip font-weight-black"
            color="white"
            text-color="black"
            v-text="selectedPartList.length"
          />
        </v-btn>
      </v-fab-transition>
    </ag-float-button-container>
    <add-part-record-container
      v-if="addRecordDialog && selectedPartList.length"
      :part-list="selectedPartList"
      :record-list="batchOperation.operation.partsRecords"
      :batch-operation="batchOperation"
      @close="closeAddrecordDialog"
      @nextPart="chainNextPart"
    />
    <ag-confirm-dialog
      v-model="deletePartConfirmationDialog"
      title="Confirmation"
      :message="deletePartTitle"
      cancel-label="Annuler"
      confirm-label="Valider"
      validation-btn-color="accent"
      text-confirmation="supprimer"
      @confirm="deletePartValidation"
    />
  </v-container>
</template>
<script>
import { mapActions, mapGetters } from 'vuex'
import Repositories from 'src/repositories'
import AgConfirmDialog from 'src/components/common/AgConfirmDialog'
import AgFloatButtonContainer from 'src/components/common/AgFloatButtonContainer'
import PartList from '../components/PartList'
import AddPartRecordContainer from './AddPartRecordContainer'

export default {
  name: 'OperationPartListTodoContainer',
  components: {
    PartList,
    AgFloatButtonContainer,
    AddPartRecordContainer,
    AgConfirmDialog,
  },
  props: {
    batchOperation: {
      type: Object,
      required: true,
    },
  },
  data: () => ({
    selectedPartList: [],
    addRecordDialog: false,
    deletePartConfirmationDialog: false,
  }),
  computed: {
    ...mapGetters('me', {
      role: 'role',
    }),
    itemPerPageAllowed () {
      if (this.batchOperation.operation.operationType.itemPerPageAllowedList.length) {
        const temp = this.batchOperation.operation.operationType.itemPerPageAllowedList.map(
          value => ({ text: value, value }),
        )
        if (
          this.batchOperation.operation.operationType.allItemPerPageAllowed
          || this.role.level > 1
        ) {
          temp.push({ text: 'Tous', value: this.batchOperation.todoList.length })
        }
        return temp
      }
      const temp = [
        { text: 3, value: 3 },
        { text: 6, value: 6 },
        { text: 10, value: 10 },
        { text: 20, value: 20 },
      ]
      if (
        this.batchOperation.operation.operationType.allItemPerPageAllowed
          || this.role.level > 1
      ) {
        temp.push({ text: 'Tous', value: this.batchOperation.todoList.length })
      }
      return temp
    },
    isMultiSelectionAllowed () {
      if (this.batchOperation.operation.operationType.multiParts || this.role.level > 1) return true
      return false
    },
    deletePartTitle () {
      return `Êtes vous sûr de vouloir supprimer ${this.selectedPartList.length} pièce(s) ?
      Elles disparaitrons définitivement du système`
    },
  },
  methods: {
    ...mapActions('ui', {
      showSuccessNotification: 'showSuccessNotification',
      showErrorNotification: 'showErrorNotification',
    }),
    ...mapActions('batch', {
      deletePartList: 'deletePartList',
    }),
    openOnePart (part) {
      this.selectedPartList = [part]
      this.addRecordDialog = true
    },
    openAddrecordDialog () {
      this.addRecordDialog = true
    },
    chainNextPart (previousDonePart) {
      const newPartList = this.batchOperation.todoList
        .filter(part => part.serialNumber > previousDonePart.serialNumber)
      if (newPartList.length) {
        this.openOnePart(newPartList[0])
      }
      else {
        this.closeAddrecordDialog()
      }
    },
    closeAddrecordDialog () {
      this.addRecordDialog = false
      this.selectedPartList = []
      this.scrollToTop()
    },
    scrollToTop () {
      document.getElementById('todo-list')
        .getElementsByClassName('v-data-table__wrapper')[0].scrollTo({
          top: 0,
          left: 0,
          behavior: 'smooth',
        })
    },
    openDeletePartConfirmationDialog () {
      this.deletePartConfirmationDialog = true
    },
    async deletePartValidation () {
      try {
        const partIdList = this.selectedPartList.map(part => part._id)
        await Repositories.part.delete(partIdList)
        await this.deletePartList(partIdList)
        this.showSuccessNotification('Les pièces ont bien été supprimées')
        this.deletePartConfirmationDialog = false
      }
      catch (error) {
        this.showErrorNotification(Repositories.parseErrorMessage(error))
      }
    },
  },
}
</script>
<style lang="scss" scoped>
.float-chip {
  cursor: pointer;
  position: absolute;
  right: 10px;
  top: 12px;
}
</style>
