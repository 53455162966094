<template>
  <div>
    <v-autocomplete
      clearable
      chips
      :class="!errorMessageList.length ? 'mb-5' : ''"
      :value="value"
      :label="label"
      :hint="description"
      persistent-hint
      hide-details="auto"
      prepend-icon="keyboard"
      v-bind="$attrs"
      autocomplete="off"
      :success="success"
      :error-messages="errorMessageList"
      :error-count="errorMessageList.length"
      outlined
      item-text="serialNumber"
      return-object
      no-data-text="Aucun element disponible en stock"
      @input="validationAfterInput($event)"
    >
      <template v-slot:item="{ item }">
        <v-list-item-content :class="getItemClass(item)">
          <v-list-item-title>{{ item.serialNumber }}</v-list-item-title>
          <v-list-item-subtitle>
            Nombre d'utilisation : {{ item.useCount }}
            <span v-if="!partIsAvailable(item)">
              - La pièce sera utilisable dans {{ partIsAvailableIn(item) }} minutes
            </span>
          </v-list-item-subtitle>
        </v-list-item-content>
      </template>
    </v-autocomplete>
    <v-switch
      v-if="!forceStock && completed && value && value.serialNumber"
      :value="stock"
      :label="switchLabel"
      @change="$emit('update:stock', $event)"
    />
  </div>
</template>

<script>

export default {
  name: 'PartRecord',
  props: {
    value: {
      type: Object,
      default: () => {},
    },
    sampling: {
      type: Boolean,
      default: false,
    },
    description: {
      type: String,
      default: '',
    },
    name: {
      type: String,
      default: '',
    },
    ok: {
      type: Boolean,
      default: false,
    },
    completed: {
      type: Boolean,
      default: false,
    },
    errorMessageList: {
      type: Array,
      default: () => [],
    },
    timer: {
      type: Number,
      default: 0,
    },
    forceStock: {
      type: Boolean,
      default: false,
    },
    stock: {
      type: Boolean,
      default: false,
    },
    switchLabel: {
      type: String,
      default: '',
    },
  },
  computed: {
    label () {
      if (!this.sampling) return `${this.name} *`
      return this.name
    },
    success () {
      if (this.value && this.value.length && this.ok && this.completed) return true
      return false
    },
  },
  methods: {
    validationAfterInput (value) {
      let tempErrorMessage = []
      tempErrorMessage = this.checkIfValueIsCompleted(value, tempErrorMessage)
      this.$emit('update:error-message-list', tempErrorMessage)
      this.$emit('input', value)
    },
    checkIfValueIsCompleted (value, messageErrorList) {
      let tempcompleted = true
      if (!this.sampling && (!value || !value.serialNumber)) {
        tempcompleted = false
      }
      this.$emit('update:completed', tempcompleted)
      return messageErrorList
    },
    partIsAvailable (item) {
      return (this.moment(item.finishAt).add(this.timer, 'h') - this.moment()) < 0
    },
    partIsAvailableIn (item) {
      return Math.trunc(((this.moment(item.finishAt).add(this.timer, 'h')) - this.moment()) / 60000)
    },
    getItemClass (item) {
      if (item.destroyed) return 'destroyed'
      if (item.controlSuspended) return 'controlSuspended'
      if (item.rejected) return 'rejected'
      if (item.retouched) return 'retouched'
      if (item.exception) return 'exception'
      return ''
    },
  },
}
</script>
