<template>
  <v-container fluid>
    <v-card>
      <v-card-text v-if="!batchOperation.doneList.length" class="text-h4 text-center">
        Aucune pièces n'a été produites
      </v-card-text>
      <part-list
        v-else
        list-type="Done"
        :multi-selection="isMultiSelectionAllowed"
        :value="batchOperation.doneList"
        :is-delay="false"
        :selected.sync="selectedPartList"
        :item-per-page-allowed="itemPerPageAllowed"
        action-button-icon="search"
        @openOnePart="viewOnePart"
      />
    </v-card>
    <ag-float-button-container>
      <v-fab-transition>
        <v-btn
          v-if="selectedPartList.length"
          append
          color="accent"
          fab
          @click="openEditMultiplePartrecordDialog"
        >
          <v-icon>edit</v-icon>
          <v-chip
            x-small
            class="px-1 float-chip font-weight-black"
            color="white"
            text-color="black"
            v-text="selectedPartList.length"
          />
        </v-btn>
      </v-fab-transition>
    </ag-float-button-container>
    <view-edit-one-part-record-container
      v-if="displayEditOnePartDialog && selectedPart"
      :part="selectedPart"
      :record-list="batchOperation.operation.partsRecords"
      :batch-operation="batchOperation"
      @close="closeViewOnePartRecordDialog"
    />
    <view-edit-multiple-part-record-container
      v-if="editMultiplePartRecordDialog && selectedPartList.length"
      :part-list="selectedPartList"
      :record-list="batchOperation.operation.partsRecords"
      :batch-operation="batchOperation"
      @close="closeEditMultiplePartrecordDialog"
    />
  </v-container>
</template>
<script>
import { mapGetters } from 'vuex'
import AgFloatButtonContainer from 'src/components/common/AgFloatButtonContainer'
import PartList from '../components/PartList'
import ViewEditOnePartRecordContainer from './ViewEditOnePartRecordContainer'
import ViewEditMultiplePartRecordContainer from './ViewEditMultiplePartRecordContainer'

export default {
  name: 'OperationPartListDoneContainer',
  components: {
    PartList,
    AgFloatButtonContainer,
    ViewEditOnePartRecordContainer,
    ViewEditMultiplePartRecordContainer,
  },
  props: {
    batchOperation: {
      type: Object,
      required: true,
    },
  },
  data: () => ({
    selectedPartList: [],
    selectedPart: null,
    displayEditOnePartDialog: false,
    editMultiplePartRecordDialog: false,
  }),
  computed: {
    ...mapGetters('me', {
      role: 'role',
    }),
    itemPerPageAllowed () {
      if (this.batchOperation.operation.operationType.itemPerPageAllowedList.length) {
        const temp = this.batchOperation.operation.operationType.itemPerPageAllowedList.map(
          value => ({ text: value, value }),
        )
        if (
          this.batchOperation.operation.operationType.allItemPerPageAllowed
          || this.role.level > 1
        ) {
          temp.push({ text: 'Tous', value: this.batchOperation.doneList.length })
        }
        return temp
      }
      const temp = [
        { text: 3, value: 3 },
        { text: 6, value: 6 },
        { text: 10, value: 10 },
        { text: 20, value: 20 },
      ]
      if (
        this.batchOperation.operation.operationType.allItemPerPageAllowed
          || this.role.level > 1
      ) {
        temp.push({ text: 'Tous', value: this.batchOperation.doneList.length })
      }
      return temp
    },
    isMultiSelectionAllowed () {
      if (this.role.level > 1) return true
      return false
    },
  },
  methods: {
    viewOnePart (part) {
      this.selectedPart = part
      this.displayEditOnePartDialog = true
    },
    closeViewOnePartRecordDialog () {
      this.displayEditOnePartDialog = false
      this.selectedPartList = []
    },
    openEditMultiplePartrecordDialog () {
      this.editMultiplePartRecordDialog = true
    },
    closeEditMultiplePartrecordDialog () {
      this.editMultiplePartRecordDialog = false
      this.selectedPartList = []
    },
  },
}
</script>
<style lang="scss" scoped>
.float-chip {
  cursor: pointer;
  position: absolute;
  right: 10px;
  top: 12px;
}
</style>
