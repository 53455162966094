<template>
  <v-radio-group
    :value="value"
    :label="label"
    :hint="description"
    persistent-hint
    prepend-icon="mouse"
    :append-outer-icon=" (!ok && value !== null) ? 'error' : ''"
    :error="!ok && value !== null"
    :success="ok && completed"
    @change="validationAfterInput($event)"
  >
    <v-radio label="Vrai" :value="true" />
    <v-radio label="faux" :value="false" />
  </v-radio-group>
</template>

<script>
export default {
  name: 'OkKoRecord',
  props: {
    value: {
      type: [Boolean, null],
      default: null,
    },
    sampling: {
      type: Boolean,
      default: false,
    },
    description: {
      type: String,
      default: '',
    },
    name: {
      type: String,
      default: '',
    },
    ok: {
      type: Boolean,
      default: false,
    },
    completed: {
      type: Boolean,
      default: false,
    },
    errorMessageList: {
      type: Array,
      default: () => [],
    },
  },
  computed: {
    label () {
      if (!this.sampling) return `${this.name} *`
      return this.name
    },
  },
  methods: {
    validationAfterInput (value) {
      let tempErrorMessage = []
      tempErrorMessage = this.checkIfValueIsCompleted(value, tempErrorMessage)
      tempErrorMessage = this.checkIfValueIsOk(value, tempErrorMessage)
      this.$emit('update:error-message-list', tempErrorMessage)
      this.$emit('input', value)
    },
    checkIfValueIsOk (value, messageErrorList) {
      if (this.sampling && value === null) {
        this.$emit('update:ok', true)
        return messageErrorList
      }
      if (value) {
        this.$emit('update:ok', true)
        return messageErrorList
      }
      this.$emit('update:ok', false)
      return messageErrorList
    },
    checkIfValueIsCompleted (value, messageErrorList) {
      if (!this.sampling && value !== null) {
        this.$emit('update:completed', true)
        return messageErrorList
      }
      this.$emit('update:completed', false)
      return messageErrorList
    },
  },
}
</script>
