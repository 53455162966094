<template>
  <v-container fluid>
    <v-card>
      <v-card-text class="pb-0">
        <add-record-form
          v-if="!displayEditMode"
          :record-list="batchOperation.operation.batchRecords"
          :part-number="partNumber"
          :batch="batch"
          :role="role"
          @validate="isValid = $event"
          @complete="isComplete = $event"
          @fill="recordListFilled = $event"
        />
        <display-edit-record-form
          v-else
          :record-list="recordListAlreadySave"
          :part-number="partNumber"
          :batch="batch"
          :role="role"
          :edition-mode="editionMode"
          @validate="isValid = $event"
          @complete="isComplete = $event"
          @fill="recordListFilled = $event"
        />
      </v-card-text>
      <v-footer v-if="showFooter" padless>
        <v-toolbar dense>
          <v-spacer />
          <v-btn
            v-if="editionMode"
            :loading="loading"
            :disabled="loading"
            color="accent"
            @click="loadSavedRecord"
          >
            Annuler
          </v-btn>
          <v-btn
            v-if="!displayEditMode || editionMode"
            :loading="loading"
            :disabled="!isComplete || loading"
            color="accent"
            class="ml-5"
            @click="validate"
          >
            Valider
          </v-btn>
          <v-btn
            v-if="displayEditMode && !editionMode && role.level > 1"
            :loading="loading"
            :disabled="loading"
            color="accent"
            class="ml-5"
            @click="editionMode = true"
          >
            Editer
          </v-btn>
          <v-spacer />
        </v-toolbar>
      </v-footer>
    </v-card>
    <ag-confirm-dialog
      v-model="dropStockConfirmationDialog"
      title="Confirmation"
      :message="dropStockTitle"
      cancel-label="Annuler"
      confirm-label="Valider"
      validation-btn-color="accent"
      :max-width="500"
      @confirm="dropStockValidation"
    />
    <rejection-selection
      v-if="RejectionDialog"
      :batch-operation="batchOperation"
      @close="RejectionDialog = false"
      @validation="validateRejection"
    />
  </v-container>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import Repositories from 'src/repositories'

import AgConfirmDialog from 'src/components/common/AgConfirmDialog'
import AddRecordForm from '../components/AddRecordForm'
import DisplayEditRecordForm from '../components/DisplayEditRecordForm'
import RejectionSelection from '../components/RejectionSelection'

export default {
  name: 'OperationRecordContainer',
  components: {
    AddRecordForm,
    DisplayEditRecordForm,
    AgConfirmDialog,
    RejectionSelection,
  },
  props: {
    batchOperation: {
      type: Object,
      required: true,
    },
  },
  data: () => ({
    isValid: false,
    isComplete: false,
    loading: false,
    recordListAlreadySave: [],
    recordListFilled: [],
    dropStockValidated: false,
    dropStockConfirmationDialog: false,
    RejectionDialog: false,
    decision: {
      cause: '',
      value: null,
    },
    rejectionCauseList: [],
    editionMode: false,
  }),
  computed: {
    ...mapGetters('batch', {
      batch: 'batch',
    }),
    ...mapGetters('me', {
      role: 'role',
      userId: 'userId',
    }),
    showFooter () {
      if (!this.displayEditMode) return true
      if (this.role.level > 1) return true
      return false
    },
    partNumber () {
      return this.batch.partNumber._id
    },
    dropStockToValidate () {
      return this.recordListFilled.some(item => item.stock)
    },
    dropStockTitle () {
      let title = ''
      for (const record of this.recordListFilled) {
        if (record.stock && record.value && record.value._id) {
          title += `Êtes vous sûr d'avoir finis d'utiliser l'élément : ${record.recordType.type} - ${record.value.serialNumber} ?  Il ne sera plus utilisable par la suite !
        `
        }
      }
      return title
    },
    displayEditMode () {
      return !this.batchOperation.batchRecordTodo
    },
  },
  watch: {
    batchOperation: {
      immediate: true,
      async handler () {
        this.loading = true
        if (!this.batchOperation.batchRecordTodo) {
          await this.loadSavedRecord()
        }
        this.loading = false
      },
    },
    role: {
      immediate: false,
      async handler (role) {
        if (role.level === 1) this.loadSavedRecord()
      },
    },
  },
  methods: {
    ...mapActions('ui', {
      showSuccessNotification: 'showSuccessNotification',
      showErrorNotification: 'showErrorNotification',
    }),
    validate () {
      if (this.dropStockToValidate && !this.dropStockValidated) {
        this.dropStockConfirmationDialog = true
        return
      }
      if (!this.isValid) {
        this.RejectionDialog = true
      }
      else {
        this.saveRecord(null)
      }
    },
    dropStockValidation () {
      this.dropStockValidated = true
      this.validate()
    },
    validateRejection (decision) {
      this.saveRecord(decision)
      this.RejectionDialog = false
    },
    async saveRecord (decision) {
      if (this.editionMode) {
        this.updateRecord(decision)
        return
      }
      const params = []
      for (const record of this.recordListFilled) {
        params.push({
          recordType: record.recordType._id,
          partNumber: this.partNumber,
          operation: this.batchOperation._id,
          operationType: this.batchOperation.operation.operationType._id,
          batch: this.batch._id,
          user: this.userId,
          value: record.value,
          type: record.recordType.type,
          part: record.recordType.part,
          ok: record.ok,
          sampling: record.recordType.sampling,
          stock: record.stock,
          destruction: record.recordType.destroy,
        })
      }
      this.loading = true
      try {
        await Repositories.record.post({
          batchId: this.batch._id,
          partIdList: null,
          recordList: params,
          decision,
          operationTypeId: this.batchOperation.operation.operationType._id,
          operationBatchId: this.batchOperation._id,
          batchTypeCreation: true,
        })
        this.showSuccessNotification('Les relevés ont bien été créés')
        this.$store.dispatch('batch/donePart', {
          batchId: this.batch._id,
          partIdList: null,
          recordList: params,
          decision,
          operationType: this.batchOperation.operation.operationType,
          operationBatchId: this.batchOperation._id,
          batchTypeCreation: true,
        })
      }
      catch (error) {
        this.showErrorNotification(Repositories.parseErrorMessage(error))
      }
      finally {
        this.loading = false
      }
    },
    async updateRecord (decision) {
      const params = []
      for (const record of this.recordListFilled) {
        params.push({
          recordType: record.recordType._id,
          partNumber: this.partNumber,
          operation: this.batchOperation._id,
          operationType: this.batchOperation.operation.operationType._id,
          batch: this.batch._id,
          user: this.userId,
          value: record.value,
          type: record.recordType.type,
          part: record.recordType.part,
          ok: record.ok,
          sampling: record.recordType.sampling,
          stock: record.stock,
          destruction: record.recordType.destroy,
        })
      }
      this.loading = true
      try {
        await Repositories.record.patchMany({
          batchId: this.batch._id,
          partIdList: null,
          recordList: params,
          decision,
          operationTypeId: this.batchOperation.operation.operationType._id,
          operationBatchId: this.batchOperation._id,
          batchTypeCreation: true,
        })
        this.showSuccessNotification('Les relevés ont bien été modifiés')
        this.$store.dispatch('batch/updatePart', {
          batchId: this.batch._id,
          partIdList: null,
          recordList: params,
          decision,
          operationType: this.batchOperation.operation.operationType,
          operationBatchId: this.batchOperation._id,
          batchTypeCreation: true,
        })
        this.editionMode = false
      }
      catch (error) {
        this.showErrorNotification(Repositories.parseErrorMessage(error))
      }
      finally {
        this.loading = false
      }
    },
    async loadSavedRecord () {
      this.recordListAlreadySave = await Repositories.record.getList({
        batch: this.batch._id,
        operationType: this.batchOperation.operation.operationType._id,
        level: 'Opérations',
      })
      this.editionMode = false
    },
  },

}
</script>
