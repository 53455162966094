<template>
  <v-data-table
    fixed-header
    height="calc(100vh - 240px)"
    :value="selected"
    :headers="headers"
    :items="localItemList"
    :search="search"
    :loading="loading"
    :loading-text="'Chargement en cours...'"
    hide-default-footer
    item-key="_id"
    :show-select="multiSelection"
    sort-by="serialNumber"
    :items-per-page="partsPerPage"
    :page.sync="page"
    :item-class="getItemClass"
    @input="$emit('update:selected', $event)"
    @page-count="pageCount = $event"
    @pagination="pagination = $event"
  >
    <template #top>
      <v-toolbar
        color="secondary lighten-2"
        style="border-radius: 4px 4px 0px 0px;"
        dark
        flat
        dense
      >
        <v-row no-gutters justify="space-between" align="center">
          <v-col cols="auto">
            <v-text-field
              v-model="search"
              style="width: 250px"
              :placeholder="'Recherche'"
              prepend-icon="search"
              hide-details
              single-line
            />
          </v-col>
          <v-col cols="auto">
            <v-row>
              <v-col cols="auto" class="px-1">
                <v-select
                  v-model="partsPerPage"
                  prefix="Pièces par page : "
                  solo
                  hide-details
                  light
                  style="width: min-content"
                  dense
                  item-text="text"
                  item-value="value"
                  :items="itemPerPageAllowed"
                />
              </v-col>
              <v-col cols="auto" class="px-0" style="align-self: center;">
                <v-btn
                  :disabled="page === 1"
                  icon
                  small
                  @click="firstPage"
                >
                  <v-icon>keyboard_double_arrow_left</v-icon>
                </v-btn>
              </v-col>
              <v-col cols="auto" class="px-0" style="align-self: center;">
                <v-btn
                  :disabled="page === 1"
                  icon
                  small
                  @click="previousPage"
                >
                  <v-icon>chevron_left</v-icon>
                </v-btn>
              </v-col>
              <v-col cols="auto" class="px-1">
                <v-select
                  v-model="page"
                  prefix="Page: "
                  solo
                  hide-details
                  light
                  style="width: min-content"
                  dense
                  :items="pageCountList"
                />
              </v-col>
              <v-col cols="auto" class="px-0" style="align-self: center;">
                <v-btn
                  :disabled="page === pageCount"
                  icon
                  small
                  @click="nextPage"
                >
                  <v-icon>chevron_right</v-icon>
                </v-btn>
              </v-col>
              <v-col cols="auto" class="px-0" style="align-self: center;">
                <v-btn
                  :disabled="page === pageCount"
                  icon
                  small
                  @click="lastPage"
                >
                  <v-icon>keyboard_double_arrow_right</v-icon>
                </v-btn>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
      </v-toolbar>
    </template>
    <template v-slot:[`item.actions`]="{ item }">
      <v-btn
        :disabled="!item.isSelectable"
        append
        outlined
        fab
        x-small
        color="accent"
        @click="$emit('openOnePart', item)"
      >
        <v-icon>{{ actionButtonIcon }}</v-icon>
      </v-btn>
    </template>
  </v-data-table>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'

export default {
  name: 'PartList',
  components: {},
  props: {
    value: {
      type: Array,
      default: () => ([]),
    },
    selected: {
      type: Array,
      default: () => ([]),
    },
    loading: {
      type: Boolean,
      default: false,
    },
    light: {
      type: Boolean,
      default: false,
    },
    itemPerPageAllowed: {
      type: Array,
      default: () => ([]),
    },
    multiSelection: {
      type: Boolean,
      default: false,
    },
    isDelay: {
      type: Boolean,
      default: false,
    },
    delayHours: {
      type: Number,
      default: 0,
    },
    actionButtonIcon: {
      type: String,
      default: 'edit',
    },
    userLevel: {
      type: Number,
      default: 1,
    },
    listType: {
      type: String,
      default: 'Todo',
    },
  },
  data: () => ({
    search: '',
    pageCount: 0,
    page: 1,
    pagination: null,
    localItemList: [],
    timer: null,
  }),
  computed: {
    ...mapGetters('ui', {
      getPartPerPage: 'partPerPage',
    }),
    headers () {
      const baseHeaders = [
        {
          text: 'Numéro',
          align: 'center',
          value: 'serialNumber',
          sortable: true,
        },
      ]

      if (this.light) {
        return baseHeaders
      }

      if (this.isDelay) {
        baseHeaders.push({
          text: 'Temps restant',
          value: 'timeRemaining',
          sortable: false,
          filterable: false,
          align: 'center',
          width: '15%',
        })
      }

      return [
        ...baseHeaders,
        {
          text: 'Actions',
          value: 'actions',
          sortable: false,
          filterable: false,
          align: 'center',
          width: '15%',
        },
      ]
    },
    pageCountList () {
      const list = []
      // eslint-disable-next-line no-plusplus
      for (let index = 1; index <= this.pageCount; index++) {
        list.push(index)
      }
      return list
    },
    partsPerPage: {
      get () {
        return this.getPartPerPage(this.listType)
      },
      set (newValue) {
        switch (this.listType) {
          case 'Todo':
            this.setCurrentPartsPerPageTodoList(newValue)
            break
          case 'Done':
            this.setCurrentPartsPerPageDoneList(newValue)
            break
          case 'Suspended':
            this.setCurrentPartsPerPageSuspendedList(newValue)
            break
          default:
            break
        }
      },
    },
  },
  watch: {
    value: {
      immediate: true,
      async handler () {
        // eslint-disable-next-line prefer-destructuring
        // this.itemPerPageAllowed[0].value
        this.initPartPerPage()
        this.setItemsList()
        if (this.timer) {
          clearInterval(this.timer)
        }
        if (this.isDelay) {
          const self = this
          this.timer = setInterval(() => {
            self.setItemsList()
          }, 30000)
        }
      },
    },
  },
  beforeDestroy () {
    if (this.timer) {
      clearInterval(this.timer)
    }
  },
  methods: {
    ...mapActions('ui', {
      setCurrentPartsPerPageDoneList: 'setCurrentPartsPerPageDoneList',
      setCurrentPartsPerPageTodoList: 'setCurrentPartsPerPageTodoList',
      setCurrentPartsPerPageSuspendedList: 'setCurrentPartsPerPageSuspendedList',
    }),
    initPartPerPage () {
      const currentPartPerPage = this.partsPerPage
      let currentCloserValue = null
      let currentMinDiff = null
      for (const item of this.itemPerPageAllowed) {
        const diff = Math.abs(currentPartPerPage - item.value)
        if (!currentCloserValue || diff < currentMinDiff) {
          currentCloserValue = item.value
          currentMinDiff = diff
        }
      }
      this.partsPerPage = currentCloserValue
    },
    setItemsList () {
      if (this.isDelay) {
        this.localItemList = this.value.map(part => ({
          ...part,
          isSelectable: this.isSelectable(part) || this.userLevel > 1,
          timeRemaining: this.timeRemaining(part),
        }))
      }
      else {
        this.localItemList = this.value.map(part => ({
          ...part,
          isSelectable: this.isSelectable(part),
        }))
      }
    },
    isSelectable (part) {
      if (!this.isDelay) {
        return true
      }
      if (this.moment(part.lastRecord).add(this.delayHours, 'h') < this.moment()) return true
      return false
    },
    timeRemaining (part) {
      if (this.isSelectable(part)) return 'Pièce disponible'
      const hours = this.moment(part.lastRecord).add(this.delayHours, 'h').diff(this.moment(), 'hours')
      const minutes = this.moment(part.lastRecord).add(this.delayHours, 'h').diff(this.moment(), 'minutes') - (hours * 60)
      return `${hours}h ${minutes}m`
    },
    getItemClass (item) {
      if (item.destroyed) return 'destroyed'
      if (item.rejected) return 'rejected'
      if (item.controlSuspended) return 'controlSuspended'
      if (item.retouched) return 'retouched'
      if (item.exception) return 'exception'
      return ''
    },
    nextPage () {
      if (this.page < this.pageCount) {
        this.page += 1
      }
    },
    previousPage () {
      if (this.page > 1) {
        this.page -= 1
      }
    },
    firstPage () {
      this.page = 1
    },
    lastPage () {
      this.page = this.pageCount
    },
  },
}
</script>
