<template>
  <v-card outlined class="mb-5">
    <div class="v-label text-center pa-4 pb-2">
      {{ label }}
    </div>
    <div v-if="description" class="text-caption text-center pa-1">
      {{ description }}
    </div>
    <v-card-text>
      <v-text-field
        disabled
        :value="value.surface"
        class="mb-1"
        label="Surface de filtration"
        hide-details="auto"
        persistent-hint
        hint="m²"
        autocomplete="off"
        type="number"
        outlined
      />
      <v-text-field
        :value="value.debit"
        class="mb-1"
        label="Débit"
        hide-details="auto"
        persistent-hint
        hint="L/h"
        prepend-icon="keyboard"
        autocomplete="off"
        type="number"
        outlined
        @input="setDebit($event)"
      />
      <v-text-field
        :value="value.pressure"
        class="mb-1"
        label="Pression"
        hide-details="auto"
        persistent-hint
        hint="bar"
        prepend-icon="keyboard"
        autocomplete="off"
        type="number"
        outlined
        @input="setPressure($event)"
      />
      <v-text-field
        :value="value.temperature"
        class="mb-1"
        label="Température"
        hide-details="auto"
        persistent-hint
        hint="°C"
        prepend-icon="keyboard"
        autocomplete="off"
        type="number"
        outlined
        @input="setTemperature($event)"
      />
      <v-text-field
        readonly
        :class="!errorMessageList.length ? 'mb-5' : 'mb-5'"
        :value="value.permeability"
        label="Perméabilité"
        type="number"
        hint="L/hm²bar@20°C"
        persistent-hint
        hide-details="auto"
        :append-outer-icon=" (value.permeability && !ok) ? 'error' : ''"
        v-bind="$attrs"
        autocomplete="off"
        :success="success"
        :error-messages="errorMessageList"
        :error-count="errorMessageList.length"
        outlined
        @input="validationAfterInput($event)"
      />
    </v-card-text>
  </v-card>
</template>

<script>
export default {
  name: 'PermeabilityRecord',
  props: {
    value: {
      type: Object,
      default: () => {},
    },
    sampling: {
      type: Boolean,
      default: false,
    },
    description: {
      type: String,
      default: '',
    },
    name: {
      type: String,
      default: '',
    },
    interval: {
      type: Boolean,
      default: false,
    },
    min: {
      type: String,
      default: '',
    },
    max: {
      type: String,
      default: '',
    },
    ok: {
      type: Boolean,
      default: false,
    },
    completed: {
      type: Boolean,
      default: false,
    },
    errorMessageList: {
      type: Array,
      default: () => [],
    },
  },
  computed: {
    label () {
      if (!this.sampling) return `${this.name} *`
      return this.name
    },
    success () {
      if (this.value.permeability && this.ok && this.completed) return true
      return false
    },
  },
  methods: {
    setDebit (value) {
      if (value.length) {
        this.value.debit = parseFloat(value)
      }
      else {
        this.value.debit = null
      }
      this.validationAfterInput()
    },
    setPressure (value) {
      this.value.pressure = parseFloat(value)
      this.validationAfterInput()
    },
    setTemperature (value) {
      this.value.temperature = parseFloat(value)
      this.validationAfterInput()
    },
    validationAfterInput () {
      let tempErrorMessage = []
      this.permeabilityCalculation()
      tempErrorMessage = this.checkIfValueIsCompleted(this.value, tempErrorMessage)
      tempErrorMessage = this.checkIfValueIsOk(this.value, tempErrorMessage)
      this.$emit('update:error-message-list', tempErrorMessage)
      this.$emit('input', this.value)
    },
    permeabilityCalculation () {
      if (this.value.debit && this.value.pressure && this.value.temperature) {
        // eslint-disable-next-line max-len
        this.value.permeability = Math.round((this.value.debit / (this.value.pressure * this.value.surface)) * (1.002 * Math.exp(3.056 * ((20 - this.value.temperature) / (this.value.temperature + 105)))))
      }
      else {
        this.value.permeability = null
      }
    },
    checkIfValueIsOk (value, messageErrorList) {
      if (this.sampling && !value.permeability) {
        this.$emit('update:ok', true)
        return messageErrorList
      }
      if (!value.permeability && value.permeability !== 0) {
        this.$emit('update:ok', false)
        messageErrorList.push('Veuillez remplir tous les champs')
        return messageErrorList
      }
      let tempOk = true
      if (this.interval && this.min) {
        if (value.permeability < Number.parseFloat(this.min)) {
          tempOk = false
          messageErrorList.push(`La valeur doit être supérieur à ${this.min}`)
        }
      }
      if (this.interval && this.max) {
        if (value.permeability > Number.parseFloat(this.max)) {
          tempOk = false
          messageErrorList.push(`La valeur doit être inférieur à ${this.max}`)
        }
      }
      this.$emit('update:ok', tempOk)
      return messageErrorList
    },
    checkIfValueIsCompleted (value, messageErrorList) {
      let tempcompleted = true
      if (!this.sampling || value.permeability) {
        if (!value.permeability && value.permeability !== 0) {
          tempcompleted = false
        }
      }
      this.$emit('update:completed', tempcompleted)
      return messageErrorList
    },
  },
}
</script>
