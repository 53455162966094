<template>
  <ag-modal-container
    :title="title"
    width="600px"
    no-content-padding
    @close="close"
  >
    <declaration-details-list :declaration-list="declarationList" />
    <display-edit-record-form
      :record-list="recordListAlreadySave"
      :part-number="partNumber"
      :batch="batch"
      :role="role"
      :edition-mode="editionMode"
      @validate="isValid = $event"
      @complete="isComplete = $event"
      @fill="recordListFilled = $event"
    />
    <template v-if="role.level > 1" v-slot:footer>
      <v-spacer />
      <v-btn
        v-if="declarationMode"
        color="accent"
        class="ml-5"
        @click="$emit('takeDecision')"
      >
        Prendre une décision
      </v-btn>
      <v-btn
        v-if="editionMode"
        :loading="loading"
        :disabled="!isComplete"
        color="accent"
        class="ml-5"
        @click="validate"
      >
        Valider
      </v-btn>
      <v-btn
        v-if="!editionMode && !declarationMode"
        :loading="loading"
        :disabled="loading"
        color="accent"
        class="ml-5"
        @click.stop="editionMode = true"
      >
        Editer
      </v-btn>
      <v-spacer />
    </template>
    <ag-confirm-dialog
      v-model="dropStockConfirmationDialog"
      title="Confirmation"
      :message="dropStockTitle"
      cancel-label="Annuler"
      confirm-label="Valider"
      validation-btn-color="accent"
      :max-width="500"
      @confirm="dropStockValidation"
    />
    <rejection-selection
      v-if="rejectionDialog"
      :batch-operation="batchOperation"
      @close="rejectionDialog = false"
      @validation="validateRejection"
    />
  </ag-modal-container>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import Repositories from 'src/repositories'

import AgModalContainer from 'src/components/common/AgModalContainer'
import AgConfirmDialog from 'src/components/common/AgConfirmDialog'
import DisplayEditRecordForm from '../components/DisplayEditRecordForm'
import RejectionSelection from '../components/RejectionSelection'
import DeclarationDetailsList from '../components/DeclarationDetailsList'

export default {
  name: 'ViewEditMultiplePartRecordContainer',
  components: {
    AgModalContainer,
    DisplayEditRecordForm,
    AgConfirmDialog,
    RejectionSelection,
    DeclarationDetailsList,
  },
  props: {
    batchOperation: {
      type: Object,
      required: true,
    },
    partList: {
      type: Array,
      default: () => [],
    },
    recordList: {
      type: Array,
      default: () => [],
    },
    declarationMode: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
    isValid: false,
    isComplete: false,
    loading: false,
    recordListAlreadySave: [],
    recordListFilled: [],
    dropStockValidated: false,
    dropStockConfirmationDialog: false,
    rejectionDialog: false,
    decision: {
      cause: '',
      value: null,
    },
    rejectionCauseList: [],
    editionMode: false,
  }),
  computed: {
    ...mapGetters('batch', {
      batch: 'batch',
    }),
    ...mapGetters('me', {
      role: 'role',
      userId: 'userId',
    }),
    multiplePart () {
      return this.partList.length > 1
    },
    title () {
      if (this.multiplePart) {
        return `Edition de ${this.partList.length} pièces :${this.partList[0].serialNumber} à ${this.partList[this.partList.length - 1].serialNumber}`
      }
      return `Edition de la pièce ${this.partList[0].serialNumber}`
    },
    partNumber () {
      return this.batch.partNumber._id
    },
    dropStockToValidate () {
      return this.recordListFilled.some(item => item.stock)
    },
    dropStockTitle () {
      let title = ''
      for (const record of this.recordListFilled) {
        if (record.stock && record.value && record.value._id) {
          title += `Êtes vous sûr d'avoir finis d'utiliser l'élément : ${record.recordType.type} - ${record.value.serialNumber} ?  Il ne sera plus utilisable par la suite !
        `
        }
      }
      return title
    },
    declarationList () {
      return this.part ? this.part.declarationData : []
    },
  },
  watch: {
    part: {
      immediate: true,
      async handler () {
        this.loading = true
        await this.loadSavedRecord()
        this.loading = false
      },
    },
  },
  methods: {
    ...mapActions('ui', {
      showSuccessNotification: 'showSuccessNotification',
      showErrorNotification: 'showErrorNotification',
    }),
    close () {
      this.$emit('close')
    },
    validate () {
      if (this.dropStockToValidate && !this.dropStockValidated) {
        this.dropStockConfirmationDialog = true
        return
      }
      if (!this.isValid) {
        this.rejectionDialog = true
      }
      else {
        this.updateRecord(null)
      }
    },
    dropStockValidation () {
      this.dropStockValidated = true
      this.validate()
    },
    validateRejection (decision) {
      this.rejectionDialog = false
      this.updateRecord(decision)
    },
    async updateRecord (decision) {
      const params = []
      let partIdList = []
      for (const record of this.recordListFilled) {
        params.push({
          recordType: record.recordType._id,
          partNumber: this.partNumber,
          operation: this.batchOperation._id,
          operationType: this.batchOperation.operation.operationType._id,
          batch: this.batch._id,
          user: this.userId,
          value: record.value,
          type: record.recordType.type,
          part: record.recordType.part,
          ok: record.ok,
          sampling: record.recordType.sampling,
          stock: record.stock,
          destruction: record.recordType.destroy,
        })
      }
      partIdList = this.partList.map(item => item._id)
      this.loading = true
      try {
        await Repositories.record.patchMany({
          batchId: this.batch._id,
          partIdList,
          recordList: params,
          decision,
          operationTypeId: this.batchOperation.operation.operationType._id,
          operationBatchId: this.batchOperation._id,
          batchTypeCreation: false,
        })
        this.showSuccessNotification('Les relevés ont bien été modifiés')
        for (const partId of partIdList) {
          // eslint-disable-next-line no-await-in-loop
          await this.$store.dispatch('batch/updatePart', {
            batchId: this.batch._id,
            partIdList: [partId],
            recordList: params,
            decision,
            operationType: this.batchOperation.operation.operationType,
            operationBatchId: this.batchOperation._id,
            batchTypeCreation: false,
          })
        }
        this.close()
      }
      catch (error) {
        this.showErrorNotification(Repositories.parseErrorMessage(error))
      }
      finally {
        this.loading = false
      }
    },
    async loadSavedRecord () {
      this.recordListAlreadySave = []
      const recordList = await Repositories.record.getList({
        parts: this.partList.map(part => part._id),
        operationType: this.batchOperation.operation.operationType._id,
        declarationMode: this.declarationMode,
        level: 'Pièces',
      })
      for (const record of recordList) {
        let find = false
        for (const recordSave of this.recordListAlreadySave) {
          if (recordSave.recordType._id === record.recordType._id) {
            if (
              recordSave.value
              && recordSave.value.serialNumber
              && recordSave.value.serialNumber !== record.value.serialNumber
            ) {
              recordSave.value = null
            }
            if (
              recordSave.value
              && !recordSave.value.serialNumber
              && recordSave.value !== record.value
            ) {
              recordSave.value = null
            }

            if (
              typeof recordSave.value === 'boolean'
              && recordSave.value !== record.value
            ) {
              recordSave.value = null
            }
            find = true
          }
        }
        if (!find) this.recordListAlreadySave.push(record)
      }
      this.editionMode = false
    },
  },

}
</script>
